<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import test from "@/app.config.json";
  import moment from 'moment'
  import axios from "axios";
  import vue2Dropzone from 'vue2-dropzone'
  import 'vue2-dropzone/dist/vue2Dropzone.min.css'

  export default {
    page: {title: "EINSTUDIO-Files",meta: [{name: "description"}]},
    components: {
      Layout,PageHeader, vueDropzone: vue2Dropzone
    },data() {
      return {
        title: "FILES",
        items: [{text: "Home",href:"/"}, {text: "Files",active: true}],
        baseUrl:test.baseUrl,fileUrl:test.fileUrl,user:[],files:[],groupId:"",isOnlyTeacher:false,isPassword:false,uploadedFiles: [],fileindex:0,fileArray:[],myGorups:[],
        dropzoneOptions: {
          url: "https://httpbin.org/post",
          thumbnailWidth: 200,
          maxFilesize: 25,
          timeout: 180000,
          parallelUploads: 100,
          addRemoveLinks: true,
          acceptedFiles: ".img,.jpg,.png,.gif,.pptx,.pdf,.xlsx,.docx",
          accept: this.checkDupl
        }
      };
      },created:function(){
        this.user = JSON.parse(localStorage.getItem('user'));
        this.loadData();
      },methods: {
         addData(){
         let loader = this.$loading.show();

         var formToPost = new FormData(document.getElementById("add-file-form"));

         formToPost.append("userId",this.user.id)
         formToPost.append("status",true)
         

         for (let index = 0; index < this.uploadedFiles.length; index++) {
          const element = this.uploadedFiles[index];
          formToPost.append("files",element)
         }
         axios.post(`${this.baseUrl}/Group/File/Add`, formToPost, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => 
        {
          loader.hide();
          this.$swal("",response.data.message, "success");
          this.loadData();
          this.hideModal('modal-lg');
        })
        .catch((error) => 
        {
          loader.hide();
          var html="";
          if(error.response.data.length>0)
          {
            for (let index = 0; index < error.response.data.length; index++) 
            {
              html +="<small>"+ error.response.data[index].message+"</small><hr>";
            }
          }
          else
          {
            html ="<p class='small'>"+ error.response.data.message+"</p>";
          }
          this.$swal("",html,"error");
        });
      },loadData(){

        let loader=this.$loading.show();

        let url= this.baseUrl+"/Group/File/GetAll?status=true&pagesize=0&pageindex=0";
        if(this.user.rol!='admin')
        url=url+"&userId="+this.user.id

          axios.get(url,{
            headers:{ Authorization: `Bearer ${this.user.jwt}`}
          }).then((response)=>{
              this.files= response.data;
              loader.hide();
          }).catch((error)=>{
           const html ="<small>"+ error.response.data.message+"</small>";
           this.$swal("",html,"error");
          })
      },dropzoneLoadedFile(file) {
        this.uploadedFiles.push(file);
      },checkDupl(file, done) {
        let v = this.$refs.FileDropzone;
        let files = v.getAcceptedFiles()
        let item = files.find( el => el.upload.filename === file.name )
        if (item) {
          done("duplicate")
        } else {
          done()
        }
      },format_date(value)
      {
         if (value) 
         {
           return moment(String(value)).format('MM.DD.YYYY HH:mm')
          }
      },fileDetails(categoryname){
        this.$nextTick(() => 
        {
          //this.fileArray=  this.files.filter(x=>x.category==categoryname)[0].files
          this.$router.push('/filedetail/'+categoryname);
        });
      },deleteConfirm(categoryname){
        this.$swal({ 
          title: "WARNING",
          text: "Are you sure? Will be deleted along with the connected data.",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "Yes",
          cancelButtonText:"No",
          }).then((result) => {
            if (result.value) 
            {
              this.fileDelete(categoryname)
            }
          });
      },fileDelete(categoryname){
        let loader= this.$loading.show();
        axios.delete(`${this.baseUrl}/Group/File/DeleteCategory/`+categoryname, {
            headers: { Authorization: `Bearer ${this.user.jwt}`
          },
        }).then((response) => 
        {
          loader.hide();
          this.$swal("",response.data.message, "success");
          this.loadData();
        })
        .catch((error) => 
        {
          loader.hide();
          var html ="<p class='small'>"+ error.response.data.message+"</p>";
          this.$swal("",html,"error");
        });
      },getMyGroups(){

        let url= this.baseUrl+"/Group/GetAllWithoutInclude?status=true";
        if(this.user.rol!='admin')
        url=url+"&groupUserId="+this.user.id

        axios.get(url,{
            headers:{ Authorization: `Bearer ${this.user.jwt}`}
          }).then((response)=>{
              this.myGorups= response.data;
          }).catch((error)=>{
           const html ="<small>"+ error.response.data.message+"</small>";
           this.$swal("",html,"error");
          })
      },showModal(modalid) {
        this.$root.$emit('bv::show::modal',modalid)
      },hideModal(modalid) {
        this.$root.$emit('bv::hide::modal',modalid)
      }
     }
  };
</script>
<style>
.cover-image{
  width: 100%;
    height: 100px;
    object-fit: cover;
}
</style>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row mb-3">
        <div class="col-lg-12">
          <a type="button" id="add-modal"  v-b-modal.modal-lg  class="btn btn-success"  @click="getMyGroups()"><i class="fa fa-envelope"></i> + ADD NEW FILES</a>
        </div>
    </div>
    <div class="row" id="categories-div">
        <div class="col-md-4" v-for="(item, index) in files" :key="index">
            <div class="card">
                    <div class="card-body">
                    <div class="d-flex">
                        <div class="flex-1 overflow-hidden" style="width:100%">
                            <p class="text-truncate font-size-14 mb-2"><i class="fa fa-folder"></i> {{item.category}} <i 
                              v-if="(item.files[0].groupId!=null && item.files[0].groupId!='')" class="fa fa-users float-right" v-b-tooltip.hover :title="item.files[0].group.name"></i></p>
                            <p class="text-truncate font-size-14 mb-2"><i class="fa fa-calendar"></i> {{ format_date(item.files[0].createDate)}}</p>
                        </div>
                    </div>
                </div>
                <div class="card-body border-top py-3">
                    <div class="text-truncate">
                        <a type="button" v-b-tooltip.hover title="Files" class="btn btn-sm btn-info"  @click="fileDetails(item.category)"><i class="fa fa-file"></i> {{item.files.length}}</a>
                        <a type="button" v-b-tooltip.hover title="Detail" class="btn btn-sm btn-info float-right"  @click="fileDetails(item.category)"><i class="fa fa-eye"></i></a>
                        <a type="button" v-b-tooltip.hover title="Delete" v-if="(user.rol=='admin' | (user.rol=='teacher' && item.userId==user.id))"  class="btn btn-sm btn-danger float-right mr-1" @click="deleteConfirm(item.category)"><i class="fa fa-trash"></i></a>  
                    </div>
                </div>
            </div>
        </div>
    </div>
    <b-modal id="modal-lg" size="lg" title="New Group Items" title-class="font-18" hide-footer>
        <form @submit.prevent="addData()" id="add-file-form">
            
            <div class="row">
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Group Name (*)</label>
                        <select class="form-control" v-model="groupId" name="groupId" required>
                          <option value="">Select group</option>
                            <option v-for="(item, index) in myGorups" :key="index" :value="item.id">{{item.name}}</option>
                        </select>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="form-group">
                        <label>Category Name (*)</label>
                        <input type="text" class="form-control" name="name" autocomplete="off" required/>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group">
                        <label>Explanation</label>
                        <textarea name="explanation" class="form-control"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <vue-dropzone ref="FileDropzone" name="files" id="dropzone" :options="dropzoneOptions" v-on:vdropzone-success="dropzoneLoadedFile"></vue-dropzone>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-12">
                    <button type="submit" class="btn btn-warning"><i class="fa fa-check"></i> SAVE FILES</button>
                </div>
            </div>
        </form>
    </b-modal>
  </Layout>
</template>